import React from 'react';
import { Link } from 'gatsby';

const WaterSavingMission = () => (
  <section className="aq-feature-bg py-8 bg-green text-white dark-blue-overlay">
    <div className="container-fluid">
      <div className="component-wrap">
        <div className="row pb-5">
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div>
              <h1 className="h1-two-line-v2 teal mb-15 mt-6">
                <span>Our water</span> <br />
                <span>saving mission</span>
              </h1>
              <h3 className="text-white px-0 px-lg-8 mt-5">
                Meet the Aquanauts! They're on a mission to help everyone learn
                how precious water is, and how important it is to save water
                every day. Nero loves nature. Team up with Nero to learn about
                how animals use water, and why it's so important for us to
                always leave enough water in our rivers.
              </h3>
              <br />
              <Link
                className="btn btn-light-blue mx-0 mx-lg-8"
                to="/activities"
              >
                Start your adventure here
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="/images/Characters/Isla-Watering-Can.svg"
              alt="Aquanaut Isla holding a watering can"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WaterSavingMission;
