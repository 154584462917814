import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Carousel } from 'react-bootstrap';

const MeetTheAquanauts = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <section className="pt-10 pb-7 bg-grey">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-7">
              <div className="d-lg-none">
                <h1 className="h1-two-line-v2 purple mt-6 mb-15">
                  <span>Meet the</span>
                  <br />
                  <span>Aquanauts!</span>
                </h1>
              </div>
              <Carousel
                controls={false}
                activeIndex={index}
                indicators
                onSelect={handleSelect}
                fade
                interval={3000}
                data-ride="carousel"
              >
                <Carousel.Item>
                  <div
                    className="pb-5 pb-lg-0"
                    style={{ position: 'relative' }}
                  >
                    <img
                      src="/images/imgBG.jpg"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: '-1',
                      }}
                      alt="Grey background"
                    />
                    <img
                      src="/images/Characters/Kai-Name.svg"
                      alt="Aquanaut Kai"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="pb-5 pb-lg-0">
                    <img
                      src="/images/imgBG.jpg"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: '-1',
                      }}
                      alt="Grey background"
                    />
                    <img
                      src="/images/Characters/Isla-Name.svg"
                      alt="Aquanaut Isla"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="pb-5 pb-lg-0">
                    <img
                      src="/images/imgBG.jpg"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: '-1',
                      }}
                      alt="Grey background"
                    />
                    <img
                      src="/images/Characters/Nero-Name.svg"
                      alt="Aquanaut Nero"
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="col-lg-5 d-flex align-items-center">
              <div>
                <h1 className="h1-two-line-v2 purple scaled d-none d-lg-block mt-10 mb-15">
                  <span>Meet the</span>
                  <br />
                  <span>Aquanauts!</span>
                </h1>
                <h3 className="pt-5">
                  Meet Isla, Nero and Kai. We are a fun, happy team that love
                  all things water. Jump on board the Aquabus and let’s work
                  together to discover lots of great ways to save water.
                </h3>
                <p>
                  Kai loves investigating. Join Kai in our spot the difference
                  mission and see if you can look for all the ways to use less
                  water. Then see if you can use these water saving ideas at
                  home too.
                </p>
                <br />
                <Link className="btn btn-blue" to="/about">
                  Learn more about the Aquanauts
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetTheAquanauts;
